<template>
  <div>
    <SubmitButton
      class="edit-button"
      @onButtonClick="navigateEditScreen"
    >
      <font-awesome-icon icon="edit" /> Edit
    </SubmitButton>
    <h3>Manage Profile</h3>
    <div class="profile-wrapper">
      <div class="form">
        <div class="form-group">
          <div class="label">Display Name</div>
          <div class="info">
            {{user.display_name || ""}}
          </div>
        </div>
        <div class="form-group">
          <div class="label">
            Name <br />
            (As per NRIC/Passport/Other ID)
          </div>
          <div class="info">
            {{user.name }}
          </div>
        </div>
        <div class="form-group">
          <div class="label">Mobile Number</div>
          <div class="info">
            {{user.phone}}
          </div>
        </div>
        <div class="form-group">
          <div class="label">Email</div>
          <div class="info">
            {{user.email}}
          </div>
        </div>
        <div class="form-group">
          <div class="label">Nationality</div>
          <div class="info">
            <template v-if="user.country">
              {{user.country.name || ''}}
            </template>
          </div>
        </div>
        <div class="form-group">
          <div class="label">Gender</div>
          <div class="info">
            {{user.gender | gender}}
          </div>
        </div>
        <div class="form-group">
          <div class="label">Password</div>
          <div class="info">
            ******
          </div>
        </div>
        <div class="form-group" v-if="userRole != 'admin'">
          <div class="label">Successful Referrals</div>
          <div class="info">
            <SubmitButton
              @onButtonClick="$router.push({ name: 'Success' })"
              class="view-btn"
              >View Details</SubmitButton
            >
          </div>
        </div>
      </div>
      <div class="avatar">
        <img :src="getImage" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import Input from "@/components/Input";
import Avatar from "@/assets/images/default_avatar_2x.png";
import { eventBus } from '@/main.js';

export default {
  components:{
    SubmitButton,
    Input
  },
  data(){
    return{
      user: {
        avatar_arr: []
      },
    }
  },
  computed:{
    getImage(){
      if(this.user.avatar_arr.length){
        let url = decodeURIComponent(this.user.avatar_arr[0].substring(1));
        if(url === 'users/default.png'){
          return Avatar;
        }
        return this.user.avatar_arr[0]
      }
      return "";
    },
    getProfileDataFromStore(){
      return this.$store.getters.getUserProfile;
    }
  },
  methods:{
    navigateEditScreen(){
      if(this.userRole == 'admin'){
        this.$router.push({ name: 'AdminEditProfile' })
      }else{
        this.$router.push({ name: 'EditProfile' })
      }
    }
  },
  filters:{
    gender(gender){
      return gender === 'M' ? 'Male' : 'Female'
    }
  },
  async created() {
    eventBus.$emit('loader',true);
    this.user = this.getProfileDataFromStore;
    eventBus.$emit('loader',false);

  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  display: inline;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
  // float: right;
}

.profile-wrapper {
  position: relative;
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: 4fr 1fr;
  grid-template-areas: "form avatar";
  gap: 0.5rem;
  @include respond(tab-port) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "avatar"
      "form";
    text-align: start;
    font-size: $twelve;
  }
  .form {
    grid-area: form;
    position: relative;
    &-group {
      display: flex;
      flex-direction: row;
      padding: 0.5rem;

      .label {
        width: 40%;
        // width: 20rem;
        margin: auto;

        &::after {
          content: "";
          height: 100%;
          position: absolute;
          top: 0%;
          left: 40%;
          border-right: 1px solid $gray;

          @include respond(tab-port) {
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-top: $gray solid 1px;
            border-right: 0;
          }
        }
      }
      .info {
        width: 60%;
        margin: auto;
        margin-left: $ten;

        &-input {
          width: 100%;
          margin: $twelve 0;
          font-size: $twelve;
          padding: $ten;
        }
      }
      .view-btn {
        padding: 0.75rem 1rem;
        font-size: 12px;
        word-wrap: normal;
      }
    }
  }

  .avatar {
    grid-area: avatar;
    width: 100%;
    margin: $fourteen auto;
    max-height: 300px;

    img{
      object-fit: contain;
    }



    .user-avatar {

      img{
        margin: $ten auto;
      }
    }
  }
}
</style>